export default{
    form: () => {
        return {
            name: "Krijo Njesi",
            submit: {
                label: "Krijo",
                action: 'submitUnit'
            },
            fields: [
                [
                    {
                        value: '',
                        name: 'name',
                        label: 'Emri',
                        type: 'text-field',
                        style: 'flex-grow: 0.5',
                        required: true,
                        required_text: 'Kjo fushe eshte e detyrueshme'
                    },
                ],
            ]
        }
    }
}