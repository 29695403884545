<template>
  <div>
    <h1 class="is-size-4">Njesite</h1>
    <div class="columns mb-3 mt-3">
      <div class="column is-5">
        <a @click.prevent="create_unit">
          <span class="mr-2">Krijo</span>
          <i class="fa fa-plus is-pulled-right"></i>
        </a>
          <div v-for="unit in units" :key="unit.id" class="tags has-addons">
            <button class="button is-light is-fullwidth" @click.prevent="edit_unit(unit)">{{unit.name}}</button>
          </div>
      </div>
      <div class="column is-7" v-if="form_edit || form_create">
        <DaForm
          v-if="form_create"
          @submitted="submitUnit"
          :form="form_create"
        ></DaForm>
        <DaForm
          v-if="form_edit"
          @submitted="submitUnit"
          :form="form_edit"
        ></DaForm>
      </div>
    </div>
  </div>
</template>

<script>
import DaForm from "@/components/daform/index.vue";
import createUnit from "../forms/units/create";
import editUnit from "../forms/units/edit";
import UnitsServices from "@/services/Unit";
export default {
  components: {
    DaForm,
  },
  data() {
    return {
      selectedUnit: {},
      units: [],
      form_create: null,
      form_edit: null,
    };
  },
  methods: {
    create_unit() {
      this.form_edit = null;
      this.form_create = createUnit.form();
    },
    edit_unit(unit) {
      this.form_create = null;
      this.form_edit = null;
      setTimeout(() => {
        let form = editUnit.form(unit);

        this.form_edit = form;
        this.selectedUnit = unit;
      });
    },
    async submitUnit(u) {
      var notify_text = "";
      if (this.form_create != null) {
        this.units.push(await UnitsServices.createUnit(u));
        notify_text = "Njesia u krijua me sukses!";
        this.form_create = null;
        this.$root.selected_one = {};
      } else {
        u.id = this.selectedUnit.id;
        let unit = await UnitsServices.editUnit(u);
        this.units = [
          ...this.units.map((un) => {
            if (un.id == unit.id) un = { ...unit };
            return un;
          }),
        ];
        notify_text = "Qyteti u modifikua me sukses!";
        this.form_edit = null;
        this.$root.selected_one = {};
      }
      this.$toast.success(notify_text);
    },
  },
  async created() {
    this.units = await UnitsServices.getUnits();
  },
};
</script>

<style scoped>
</style>