export default {
    form: () => {
      return {
        name: "Krijo Konfigurim",
        submit: {
          label: "Krijo",
          action: "submitConfig",
        },
        fields: [
          [
            {
              value: "",
              name: "code",
              label: "Kodi",
              type: "text-field",
              style: "flex-grow: 0.3",
              required: true,
              required_text: "Kjo fushe eshte e detyrueshme",
              unique: {
                model: "config",
                field: "code",
                where: [],
              },
              left_icon: "fa-solid fa-hashtag",
            },
            {
              value: "",
              name: "name",
              label: "Emri",
              type: "text-field",
              style: "flex-grow: 0.5",
              required: true,
              required_text: "Kjo fushe eshte e detyrueshme",
            },
          ],

          [
            {
                value: "",
                name: "description",
                label: "Pershkrimi",
                type: "text-field",
                style: "flex-grow: 0.2",
                required: true,
                required_text: "Kjo fushe eshte e detyrueshme",
            },
            {
                value: "",
                name: "nipt",
                label: "Nipt",
                type: "text-field",
                style: "flex-grow: 0.2",
                required: true,
                required_text: "Kjo fushe eshte e detyrueshme",
            },
            {
                value: "",
                name: "address",
                label: "Adresa",
                type: "text-field",
                style: "flex-grow: 0.2",
                required: true,
                required_text: "Kjo fushe eshte e detyrueshme",
            },
          ],
          [
            {
                value: "",
                name: "telephone",
                label: "Telefoni",
                type: "number-field",
                style: "flex-grow: 0.4",
                required: true,
                required_text: "Kjo fushe eshte e detyrueshme",
            },
            {
                value: "",
                name: "logo",
                label: "Logo",
                type: "text-field",
                style: "flex-grow: 0.4",
                required: true,
                required_text: "Kjo fushe eshte e detyrueshme",
            },
          ],
          [
            {
                value: "",
                name: "email",
                label: "Email",
                type: "email-field",
                style: "flex-grow: 0.4",
                required: true,
                required_text: "Kjo fushe eshte e detyrueshme",
            },
            {
                value: "",
                name: "web",
                label: "Web",
                type: "text-field",
                style: "flex-grow: 0.4",
                required: true,
                required_text: "Kjo fushe eshte e detyrueshme",
            },
          ]
        ],
      };
    },
  };
  
  