<template>
  <div>
    <h1 class="is-size-3 mb-4">Konfigurimet Gjenerale</h1>
    <h1 class="is-size-4">Te Dhenat per Kompanine</h1>
    <div class="columns mt-3">
      <div class="column is-8">
        <input
          class="input"
          style="margin: 5px; width: 300px"
          type="text"
          placeholder="Kerko..."
          v-model="global_search_filter"
          @input="onFilterTextBoxChanged"
        />
        <a @click.prevent="create_config">
          <i class="fa fa-plus is-pulled-right"></i>
        </a>
        <ag-grid-vue
          style="width: 100%; height: 200px"
          class="ag-theme-balham"
          :columnDefs="columnDefs"
          :rowData="rowData"
          :rowSelection="rowSelection"
          :context="context"
          :localeText="localeText"
          :animateRows="true"
          @grid-ready="onGridReady"
        >
        </ag-grid-vue>
      </div>
      <div class="column" v-if="form_edit || form_create">
        <DaForm
          v-if="form_create"
          @submitted="submitConfig"
          :form="form_create"
        ></DaForm>
        <DaForm
          v-if="form_edit"
          @submitted="submitConfig"
          :form="form_edit"
        ></DaForm>
      </div>
    </div>

    <cities-grid></cities-grid>
    <units-grid></units-grid>
  </div>
</template>

<script>
import CitiesGrid from "./components/cities-grid.vue";
import UnitsGrid from "./components/units-grid.vue";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { AgGridVue } from "ag-grid-vue3";
import DaForm from "@/components/daform/index.vue";
import ButtonsCell from "@/components/buttonscell/ButtonsCell.vue";
import createConfig from "./forms/general-configs/create";
import editConfig from "./forms/general-configs/edit";
import locale from "@/components/ag-grid/locale/al/al.js";
import ConfigsServices from "@/services/Config";
export default {
  components: {
    AgGridVue,
    DaForm,
    CitiesGrid,
    UnitsGrid,
  },
  beforeMount() {
    this.context = { componentParent: this };
  },
  data() {
    return {
      global_search_filter: "",
      context: null,
      selectedConfig: {},
      form_create: null,
      form_edit: null,
      columnDefs: [
        {
          headerName: "Kodi",
          field: "code",
          filter: true,
          floatingFilter: true,
          sortable: true,
        },
        {
          headerName: "Emri",
          field: "name",
          filter: true,
          floatingFilter: true,
          sortable: true,
        },
        {
          headerName: "Pershkrimi",
          field: "description",
          filter: true,
          floatingFilter: true,
          sortable: true,
        },
        {
          headerName: "Nipt",
          field: "nipt",
          filter: true,
          floatingFilter: true,
          sortable: true,
        },
        {
          headerName: "Adresa",
          field: "address",
          filter: true,
          floatingFilter: true,
          sortable: true,
        },
        {
          headerName: "Telefoni",
          field: "telephone",
          filter: true,
          floatingFilter: true,
          sortable: true,
        },
        {
          headerName: "Logo",
          field: "logo",
          filter: true,
          floatingFilter: true,
          sortable: true,
        },
        {
          headerName: "Email",
          field: "email",
          filter: true,
          floatingFilter: true,
          sortable: true,
        },
        {
          headerName: "Web",
          field: "web",
          filter: true,
          floatingFilter: true,
          sortable: true,
        },
        {
            headerName         : "Action",
            cellRenderer       : ButtonsCell,
            cellRendererParams : this.context,
        }
      ],
      rowData: [],
      gridApi: null,
      defaultColDef: {
        flex: 1,
        minWidth: 100,
      },
      rowSelection: "single",
      localeText: null,
    };
  },
  methods: {
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.global_search_filter)
    },
    open(mode, config) {
        if(mode == "1") {
            this.form_create = null
            if(config) {
                this.form_edit = null
                setTimeout(() => {
                    let form = editConfig.form(
                        config,
                    )

                    this.form_edit = form
                    this.selectedConfig = config
                })
            }
        }
    },
    onGridReady(params) {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
    },
    create_config() {
        this.form_edit = null
        this.form_create = createConfig.form()
    },
    async submitConfig(c) {
        var notify_text = ""
        if(this.form_create != null) {
            this.rowData.push(await ConfigsServices.createConfig(c))
            notify_text = "Konfigurimi u krijua me sukses!"
            this.form_create = null
            this.$root.selected_one = {}
        }
        else {
            c.guid = this.selectedConfig.guid
            let config = await ConfigsServices.editConfig(c)
            this.rowData = [...this.rowData.map(co => {
                if(co.guid == config.guid) co = {...config}
                return co
            })]
            notify_text = "Konfigurimi u modifikua me sukses!"
            this.form_edit = null
            this.$root.selected_one = {}
        }
        this.$toast.success(notify_text)
    }
  },
  async created() {
    this.localeText = locale.locale_al()
    this.rowData = await ConfigsServices.getConfigs()
}
};
</script>

<style scoped>
</style>