<template>
  <div>
    <h1 class="is-size-4">Qyteti</h1>
    <div class="columns mt-3 mb-3">
      <div class="column is-7">
        <input
          class="input"
          style="margin: 5px; width: 300px"
          type="text"
          placeholder="Kerko..."
          v-model="global_search_filter"
          @input="onFilterTextBoxChanged"
        />
          <a @click.prevent="create_city">
            <i class="fa fa-plus is-pulled-right"></i>
          </a>
        <ag-grid-vue
          style="width: 100%; height: 200px"
          class="ag-theme-balham"
          :columnDefs="columnDefs"
          :rowData="city_list"
          :rowSelection="rowSelection"
          :context="context"
          :localeText="localeText"
          :animateRows="true"
          @grid-ready="onGridReady"
        >
        </ag-grid-vue>
      </div>
      <div class="column is-5" v-if="form_edit || form_create">
        <DaForm
          v-if="form_create"
          @submitted="submitCity"
          :form="form_create"
        ></DaForm>
        <DaForm
          v-if="form_edit"
          @submitted="submitCity"
          :form="form_edit"
        ></DaForm>
      </div>
    </div>
  </div>
</template>

<script>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { AgGridVue } from "ag-grid-vue3";
import DaForm from "@/components/daform/index.vue";
import ButtonsCell from "@/components/buttonscell/ButtonsCell.vue";
import createCity from "../forms/cities/create";
import editCity from "../forms/cities/edit";
import { storeToRefs } from "pinia";
import { useCityStore } from "@/stores/city";
import locale from "@/components/ag-grid/locale/al/al.js"
import CitiesServices from "@/services/City";
export default {
  components: {
    AgGridVue,
    DaForm,
  },
  beforeMount() {
    this.context = { componentParent: this };
  },
  data() {
    return {
      global_search_filter: "",
      context: null,
      selectedCity: {},
      cities: [],
      form_create: null,
      form_edit: null,
      columnDefs: [
        {
          headerName: "Emri",
          field: "name",
          filter: true,
          floatingFilter: true,
          sortable: true,
        },
        {
          headerName         : "Action",
            cellRenderer       : ButtonsCell,
            cellRendererParams : this.context,
        }
      ],
      city_list: storeToRefs(useCityStore()).rowData,
      // rowData: [],
      gridApi: null,
      defaultColDef: {
        flex: 1,
        minWidth: 100,
      },
      rowSelection: "single",
      localeText: null,
    };
  },
  methods: {
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.global_search_filter)
    },
    open(mode, city) {
        if(mode == "1") {
            this.form_create = null
            if(city) {
                this.form_edit = null
                setTimeout(() => {
                    let form = editCity.form(
                        city,
                    )

                    this.form_edit = form
                    this.selectedCity = city
                })
            }
        }
    },
    onGridReady(params) {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
    },
    create_city() {
        this.form_edit = null
        this.form_create = createCity.form()
    },
    async submitCity(c) {
        var notify_text = ""
        if(this.form_create != null) {
            this.city_list.push(await CitiesServices.createCity(c))
            notify_text = "Qyteti u krijua me sukses!"
            this.form_create = null
            this.$root.selected_one = {}
        }
        else {
            c.guid = this.selectedCity.guid
            let city = await CitiesServices.editCity(c)
            this.city_list = [...this.city_list.map(ci => {
                if(ci.guid == city.guid) ci = {...city}
                return ci
            })]
            notify_text = "Qyteti u modifikua me sukses!"
            this.form_edit = null
            this.$root.selected_one = {}
        }
        this.$toast.success(notify_text)
    }
  },
  async created() {
    this.localeText = locale.locale_al()
    this.city_list = await CitiesServices.getCities()
}
};
</script>

<style scoped>
</style>