import Api from "@/services/Api";

export default {
    async getConfigs() {
        var configs = []
        await Api(true).post("general_configs/list", {})
        .then(r => {
            configs = r.data.map(e => {
                e.id = e.guid
                e.label = e.name
                return e
            })
        })
        return configs
    },

    async createConfig(config) {
        await Api(true).post("general_configs/create", config)
        .then(r => {
            config = r.data
        })
        return config
    },

    async editConfig(config) {
        await Api(true).post("general_configs/edit", config)
        .then(r => {
            config = r.data
        })
        return config
    }
}