import Api from "@/services/Api";

export default {
    async getUnits() {
        var units = []
        await Api(true).post("unit/list", {})
        .then(r => {
            units = r.data.map(e => {
                e.label = e.name
                return e
            })
        })
        return units
    },

    async createUnit(unit) {
        await Api(true).post("unit/create", unit)
        .then(r => {
            unit = r.data
        })
        return unit
    },

    async editUnit(unit) {
        await Api(true).post("unit/edit", unit)
        .then(r => {
            unit = r.data
        })
        return unit
    }
}