export default {
    form: (config) => {
      return {
        name: "Modifiko Konfigurim",
        submit: {
          label: "Modifiko",
          action: "submitConfig",
        },
        fields: [
          [
            {
              value: config.code,
              name: "code",
              label: "Kodi",
              type: "text-field",
              style: "flex-grow: 0.3",
              required: true,
              required_text: "Kjo fushe eshte e detyrueshme",
              unique: {
                model: "config",
                field: "code",
                where: [],
              },
              left_icon: "fa-solid fa-hashtag",
            },
            {
              value: config.name,
              name: "name",
              label: "Emri",
              type: "text-field",
              style: "flex-grow: 0.5",
              required: true,
              required_text: "Kjo fushe eshte e detyrueshme",
            },
          ],

          [
            {
                value: config.description,
                name: "description",
                label: "Pershkrimi",
                type: "text-field",
                style: "flex-grow: 0.3",
                required: true,
                required_text: "Kjo fushe eshte e detyrueshme",
            },
            {
                value: config.nipt,
                name: "nipt",
                label: "Nipt",
                type: "text-field",
                style: "flex-grow: 0.3",
                required: true,
                required_text: "Kjo fushe eshte e detyrueshme",
            },
            {
                value: config.address,
                name: "address",
                label: "Adresa",
                type: "text-field",
                style: "flex-grow: 0.3",
                required: true,
                required_text: "Kjo fushe eshte e detyrueshme",
            },
          ],
          [
            {
                value: config.telephone,
                name: "telephone",
                label: "Telefoni",
                type: "number-field",
                style: "flex-grow: 0.4",
                required: true,
                required_text: "Kjo fushe eshte e detyrueshme",
            },
            {
                value: config.logo,
                name: "logo",
                label: "Logo",
                type: "text-field",
                style: "flex-grow: 0.4",
                required: true,
                required_text: "Kjo fushe eshte e detyrueshme",
            },
          ],
          [
            {
                value: config.email,
                name: "email",
                label: "Email",
                type: "email-field",
                style: "flex-grow: 0.4",
                required: true,
                required_text: "Kjo fushe eshte e detyrueshme",
            },
            {
                value: config.web,
                name: "web",
                label: "Web",
                type: "text-field",
                style: "flex-grow: 0.4",
                required: true,
                required_text: "Kjo fushe eshte e detyrueshme",
            },
          ]
        ],
      };
    },
  };
  
  